import { Checkbox, FormControlLabel, Grid, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainerOrderInformationContext } from "../../pages/order-page/context/container-order-information.context";
import { MaterialUIDatePicker } from "../date-picker/date-picker";
import { CommentTextBlock } from "../input/comment-text-block";
import { ContainerOrderLocation } from "./container-order-location";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingTop: theme.spacing(4),
    fontWeight: "bold",
    textAlign: theme.breakpoints.down("sm") ? "left" : "inherit",
  },
  dateContainer: {
    display: "flex",
  },
  dateSelection: {
    paddingTop: theme.spacing(4),
  },
  locationContainer: {
    marginTop: 42,
  },
  mobileLocationContainer: {
    marginTop: 0,
  },
  commentSection: {
    paddingBottom: theme.spacing(2),
  },
}));

export const ContainerOrderInformation: React.VFC = () => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const {
    setDate,
    isMorning,
    setIsMorning,
    isAfternoon,
    setIsAfternoon,
    setLocation,
    comment,
    setComment,
    isNextPossibleAppointment,
    setIsNextPossibleAppointment,
  } = useContainerOrderInformationContext();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // Reset container order information
  useEffect(
    () => {
      setDate(null);
      setLocation(null);
      setIsNextPossibleAppointment(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleDateChange = (date: DateTime | null) => {
    if (date) {
      setSelectedDate(date.toJSDate());
      setDate(date.setZone("utc", { keepLocalTime: true }).toString());
    } else {
      setSelectedDate(null);
    }
  };

  return (
    <Grid item container xs={12} justifyContent="center">
      <Grid item xs={12}>
        <Typography color="primary" variant="h5" className={classes.header}>
          {t("container.order.overview.date_and_location")}
        </Typography>
      </Grid>
      <Grid item container xs={12} className={classes.dateContainer} spacing={4}>
        <Grid item xs={isSmallScreen ? 12 : 6}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNextPossibleAppointment}
                  onChange={() => {
                    setIsNextPossibleAppointment(!isNextPossibleAppointment);
                  }}
                  name="possible-next-appointment"
                />
              }
              label={t("container.order.overview.possible_next_appointment")}
            />
          </Grid>
          <Grid item xs={12} className={classes.dateSelection}>
            <MaterialUIDatePicker
              changeDate={handleDateChange}
              selectedDate={selectedDate}
              isPastDisabled={true}
              disabled={isNextPossibleAppointment}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.dateContainer}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMorning}
                    onChange={() => {
                      setIsMorning(!isMorning);
                    }}
                    name="morning-delivery"
                  />
                }
                label={t("container.order.overview.morning_delivery")}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAfternoon}
                    onChange={() => {
                      setIsAfternoon(!isAfternoon);
                    }}
                    name="afternoon-delivery"
                  />
                }
                label={t("container.order.overview.afternoon_delivery")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={isSmallScreen ? 12 : 6}
          className={isSmallScreen ? classes.mobileLocationContainer : classes.locationContainer}
        >
          <ContainerOrderLocation />
        </Grid>
        <Grid item xs={12}>
          <CommentTextBlock value={comment} setValue={setComment} label={t("container.order.overview.comment.label")} />
        </Grid>
      </Grid>
    </Grid>
  );
};
